const initialState = {
    isAuthenticated: false,
    user: null,
    token: localStorage.getItem('token') || null,
    userId: localStorage.getItem('userId') || null,
    userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null,
    imageUrl: localStorage.getItem('profilePicture') || '',
    loading: false,
    error: null,
    vehicles: null,
    trips:null,
    terminals:null,
    transportCompanies:null,
    driver:null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGNUP_SUCCESS':
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                token: action.payload.token,
                userId: action.payload.userId,
                loading: false,
                error: null, // Reset error on success
            };
        case 'FETCH_USER_SUCCESS':
            return {
                ...state,
                userData: action.payload,
                loading: false,
                error: null, // Reset error on success
            };
        case 'CONTACT_US_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null, // Reset error on success
            };
        case 'RESET_PASSWORD_SUCCESS':
        case 'FORGET_PASSWORD_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null, // Reset error on success
            };
        case 'VIEW_TERMINAL_SUCCESS':
        case 'ADD_TERMINAL_SUCCESS':
                return {
                    ...state,
                    terminals:action.payload,
                    loading: false,
                    error: null, // Reset error on success
                };
        case 'ADD_VEHICLE_SUCCESS':
        case 'VIEW_VEHICLES_COMPANY_SUCCESS':
        case 'VIEW_VEHICLE_SUCCESS':
            return {
                ...state,
                vehicles:action.payload,
                loading: false,
                error: null, // Reset error on success
            };
            case 'VIEW_DRIVER_COMPANY_SUCCESS':
                return {
                    ...state,
                    driver:action.payload,
                    loading: false,
                    error: null, // Reset error on success
                };
            case 'ADD_TRIPS_SUCCESS':
                return {
                    ...state,
                    trips:action.payload,
                    loading: false,
                    error: null, // Reset error on success
                };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
                userId: null,
                userData: null,
                imageUrl: '',
            };
        case 'UPLOAD_PROFILE_PICTURE_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'UPLOAD_PROFILE_PICTURE_SUCCESS':
            return {
                ...state,
                loading: false,
                imageUrl: action.payload,
                error: null, // Reset error on success
            };
        case 'VIEW_ALL_COMPANIES_SUCCESS':
                return {
                    ...state,
                    transportCompanies:action.payload,
                    loading: false,
                    error: null, // Reset error on success
                };
        case 'SIGNUP_FAILURE':
        case 'FETCH_USER_FAILURE':
        case 'LOGIN_FAILURE':
        case 'CONTACT_US_FAILURE':
        case 'RESET_PASSWORD_FAILURE':
        case 'FORGET_PASSWORD_FAILURE':
        case 'UPLOAD_PROFILE_PICTURE_FAILURE':
        case 'ADD_TERMINALS_FAILURE':
        case 'VIEW_TERMINAL_FAILURE':
        case 'ADD_VEHICLE_FAILURE':
        case 'VIEW_VEHICLES_COMPANY_FAILURE':
        case 'VIEW_DRIVER_COMPANY_FAILURE':
        case 'VIEW_VEHICLE_FAILURE':
        case 'ADD_TRIPS_FAILURE':
        case 'VIEW_ALL_COMPANIES_FAILURE':
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default authReducer;
