import React from 'react'
import "./travellerProfile.css"
import  moreIcon from "../../TravellerSide/images/moreIcon.svg"
import { FaAngleRight } from "react-icons/fa6";
import user5 from "../../Company/images/user2.svg"
import callIcon from "../../Company/images/phone.svg"
import emailicon from "../images/emailIcon.svg"
import homeIcon from "../images/homeIcon.svg"
import { useNavigate } from 'react-router-dom';

const TravellerProfile = () => {
const navigate = useNavigate()

  const handleTravellerTicketBtn = ()=>{
    navigate('/travellertickets')
  }
  return (
    <div className='traveller-profile-mainContainer'>
      <div className="traveller-profile-main">

          <div className="traveller-profile-itemsContainer">
              <div className='traveller-profile-itemsContainer-top'><img src={moreIcon} alt="" /></div>
              <div className="traveller-profile-itemsContainer-top-sec2">
                <img src={user5} alt="" />
                <h2>Janatte Doe</h2>
              </div>
              <div className="traveller-profile-itemsContainer-top-sec3">
                <h3>Contact Details:</h3>
                <div className="traveller-profile-itemsContainer-top-sec3-item">
                  <img src={callIcon} alt="" />
                  <p>+234-900-123-4236</p>
                </div>
                <div className="traveller-profile-itemsContainer-top-sec3-item">
                  <img src={emailicon} alt="" />
                  <p>janedoe098@gmail.com</p>
                </div>
                <div className="traveller-profile-itemsContainer-top-sec3-item">
                  <img src={homeIcon} alt="" />
                  <p>Block 25, windinburg street, Ikeja, Lagos</p>
                </div>
              </div>
          </div>

          <div className="traveller-profile-itemsContainer2">

            <div className="traveller-profile-itemsContainer2-items">
              <div className="traveller-profile-itemsContainer2-items-main1">
                <div className="traveller-profile-itemsContainer2-items-main1-picsHolder">
                  <img src="" alt="" />
                </div>
                <h5 onClick={handleTravellerTicketBtn}>Tickets</h5>
              </div>
              <FaAngleRight onClick={handleTravellerTicketBtn} />
            </div>

            <div className="traveller-profile-itemsContainer2-items2">
              <h3>Bio</h3>
              <div className="traveller-profile-itemsContainer2-items2main">
                  <div className="traveller-profile-itemsContainer2-items2main-item">
                    <p>Gender:</p>
                    <h4>Female</h4>
                  </div>
                  <div className="traveller-profile-itemsContainer2-items2main-item">
                    <p>Date of Birth:</p>
                    <h4>11/07/1989</h4>
                  </div>
                  <div className="traveller-profile-itemsContainer2-items2main-item">
                    <p>City:</p>
                    <h4>Lagos</h4>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default TravellerProfile
