import React from 'react'
import "./Dashcontent.css"
import notificationIcon from "./images/notification.svg"
import userIcon from "./images/userIcon.svg"
import userIcon2 from "./images/userIcon2.svg"
import busFront from "./images/busFront.svg"
import angleRight from "./images/angleRight.svg"
import ActivityCrd from './component/ActivityCrd'
import Footer from '../../components/Footer'



const DashContent = () => {
  return (
    <div className='Dashcontent-container'>
      <div className='dashcontent-section1'>
        <img src={notificationIcon} alt="" />
        <img src={userIcon} alt="" />
        <p>Admin</p>
      </div>
      <div className='dashontent-header'><h1>Welcome back!</h1></div>
      <div className='dashontent-card-holder'>
      <div className='dashontent-card'>
        <div className='dashontent-card-sec1'>
          <img src={userIcon2} alt="" />
          <h2>53</h2>
        </div>
        <div className='dashontent-card-p'>
        <p>Users</p>
        </div>
       
      </div>
      <div className='dashontent-card2'>
        <div className='dashontent-card-sec1'>
          <img src={busFront} alt="" />
          <h2>100</h2>
        </div>
        <div className='dashontent-card-p'>
        <p>Transport Companies</p>
        </div>
       
      </div>
      
      </div>
      <div className='dashcontent-recent-activity-tag'>
          <p>Recent Activity</p>
          <div className='dashcontent-see-all'>
            <p>See all</p><img src={angleRight} alt="" />
          </div>
          
      </div>
      <div className='recent-activity-container'>
        <ActivityCrd/>
        <ActivityCrd/>
        <ActivityCrd/>
        <ActivityCrd/>

        
      </div>
      <div className='footer'>
        <Footer/>
      </div>
    </div>
  )
}

export default DashContent
