import React from 'react'
import "./EmailNotification.css"

const EmailNotification = () => {
  return (
    <div>
      Email Notification Page
    </div>
  )
}

export default EmailNotification
