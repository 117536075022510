import { type } from '@testing-library/user-event/dist/type';
import axios from 'axios';

// Create an instance of axios with base configuration
const api = axios.create({
    baseURL: 'https://vadtrans-api.onrender.com/api/v1',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Driver signup action
export const driverSignup = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/signup-driver', formData);
        dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({
            type: 'SIGNUP_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

// Traveler signup action
export const travelerSignup = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/signup', formData);
        dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({
            type: 'SIGNUP_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

// Company signup action
export const companySignup = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/signup-company', formData);
        dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({
            type: 'SIGNUP_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

// Admin signup action
export const adminSignup = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/admin-signup', formData);
        dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
       
    } catch (error) {
        dispatch({
            type: 'SIGNUP_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

// Login action
export const login = (formData, userType) => async (dispatch) => {
    try {
        let endpoint = '';
        switch(userType) {
            case 'driver':
                endpoint = '/login-driver';
                break;
            case 'traveler':
                endpoint = '/login';
                break;
            case 'company':
                endpoint = '/login-company';
                break;
            case 'admin':
                endpoint = '/admin-login';
                break;
            default:
                throw new Error('Invalid user type');
        }

        const response = await api.post(endpoint, formData);
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });

        // Store token and userId in localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.User.userId);
    } catch (error) {
        dispatch({
            type: 'LOGIN_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

// Fetch user data action with session timeout handling
export const fetchUserData = (userId, token, userType) => async (dispatch) => {
    try {
        // Determine the endpoint based on user type
        let endpoint;
        switch (userType) {
            case 'traveler':
                endpoint = `/get-traveller/${userId}`;
                break;
            case 'driver':
                endpoint = `/get-driver/${userId}`;
                break;
            case 'transport_company':
                endpoint = `/get-company/${userId}`;
                break;
            case 'admin':
                endpoint = `/get-admin/${userId}`;
                break;
            default:
                throw new Error("Invalid user type");
        }

        const response = await api.get(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        const userData = response.data;

        // Dispatch success action with user data
        dispatch({ type: 'FETCH_USER_DATA_SUCCESS', payload: userData });

        // Save user data to localStorage
        localStorage.setItem('userData', JSON.stringify(userData));

    } catch (error) {
        dispatch({
            type: 'FETCH_USER_DATA_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};


// Update user data action
export const updateUserData = (formData, userType, token) => async (dispatch) => {
    try {
        let endpoint = '';
        switch (userType) {
            case 'traveller':
                endpoint = '/update-profile';
                break;
            case 'driver':
                endpoint = '/update-driver';
                break;
            case 'company':
                endpoint = '/update-company';
                break;
            case 'admin':
                endpoint = '/update-admin';
                break;
            default:
                throw new Error('Invalid user type');
        }

        const response = await api.put(endpoint, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        dispatch({ type: 'UPDATE_USER_DATA_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({
            type: 'UPDATE_USER_DATA_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};



export const updateCompanyData = (formData, userId, token) => async (dispatch) => {
    try {

        const response = await api.put(`/update-company/${userId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'UPDATE_COMPANY_DATA_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'UPDATE_COMPANY_DATA_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const addTerminals = (formData, userId, token) => async (dispatch) => {
    try {

        const response = await api.post(`/add-terminal/${userId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'ADD_TERMINAL_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_TERMINAL_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const viewTerminals = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-terminals-company/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_TERMINAL_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TERMINAL_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const addCompanyVehicle = (formData, userId, token) => async (dispatch) => {
    try {

        const response = await api.post(`/add-vehicle/${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'ADD_VEHICLE_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_FAILURE_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const viewAllVehicles = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-vehicles-company/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_VEHICLES_COMPANY_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TERMINAL_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};


export const viewOneVehicles = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-vehicle/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_VEHICLE_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_VEHICLE_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const addTrips = (formData, userId, token) => async (dispatch) => {
    try {

        const response = await api.post(`/add-vehicle/${userId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch({
            type: 'ADD_TRIPS_SUCCESS',
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: 'ADD_TRIPS_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const contactUs = (formData, token) => async (dispatch) => {
    try {
        const response = await api.post('/reach-out', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        // Dispatch success action with user data
        dispatch({ type: 'CONTACT_US_SUCCESS', payload: response.data });

    } catch (error) {
        dispatch({
            type: 'CONTACT_US_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const forgetPassword = (formData) => async (dispatch) => {
    try {
        const response = await api.post('/forgot', formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Dispatch success action
        dispatch({
            type: 'FORGET_PASSWORD_SUCCESS',
            payload: response.data,
        });

    } catch (error) {
        dispatch({
            type: 'FORGET_PASSWORD_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};
export const resetPassword = (formData) => async (dispatch) => {
    try {
        const response = await api.put('/reset', formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Dispatch success action
        dispatch({
            type: 'RESET_PASSWORD_SUCCESS',
            payload: response.data,
        });

    } catch (error) {
        dispatch({
            type: 'RESET_PASSWORD_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const uploadProfilePicture = (file, userId, token) => async (dispatch) => {
    dispatch({ type: 'UPLOAD_PROFILE_PICTURE_REQUEST' });

    const formData = new FormData();
    formData.append('companyPicture', file);

    try {
        const response = await api.put(`upload-logo/`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        dispatch({
            type: 'UPLOAD_PROFILE_PICTURE_SUCCESS',
            payload: response.data.imageUrl,  // Assuming the response contains the image URL
        });

        // Optionally, save the image URL to localStorage
        localStorage.setItem('profilePicture', response.data);

    } catch (error) {
        dispatch({
            type: 'UPLOAD_PROFILE_PICTURE_FAILURE',
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};

export const getAllCompany = ( token) =>async (dispatch) =>{
    try{
        const response = await api.get('/view-all-company', {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_ALL_COMPANIES_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_ALL_COMPANIES_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};

export const viewAllDrivers = (userId, token) =>async (dispatch) =>{
    try{
        const response = await api.get(`/view-drivers-company/${userId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            });
            dispatch({
                type: 'VIEW_DRIVER_COMPANY_SUCCESS',
                payload: response.data
            });
    } catch (error){
        dispatch({
            type: 'VIEW_TERMINAL_FAILURE',
            payload: error.response? error.response.data.message : error.message
        });
    }
};










// Logout action
export const logout = () => (dispatch) => {
    // Clear user data from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userData');

    dispatch({ type: 'LOGOUT' });
};
