import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const DriversResertPassWord = () => {
    const [driverResetEmail, setDriverResetEmail] = useState(false)
    const navigate = useNavigate
    
    const handleDriverResetBtn = ()=>{
        setDriverResetEmail(true)
    }
    const handleDriverResetSuccess = ()=>{
        navigate('/driverlogin')
    }
  return (
    

    <div className='driverSignup-holder'>
        {
            driverResetEmail?
            <div className="driverSignup-mainContainer2">
            <div className="driverSignup-mainContainer-item1a">
                    <h2>Reset password email sent</h2>
                    <h5>A password rest email has been sent to <span> xyz@gmail.com. </span>  Click on the  link in email to change your password</h5>
                </div>
                <button onClick={handleDriverResetSuccess}>Return to Login Page</button>
            </div> 
            
            :
            
            <div className="driverSignup-mainContainer2">
        <div className="driverSignup-mainContainer-item1a">
                <h2>Forgot password?</h2>
                <h5>Enter email address associated with this account</h5>
            </div>
            <div className="driverSignup-mainContainer-item2">
                <label htmlFor="">Email</label>
                <input type="email" />
            </div>
       
           
            <button onClick={handleDriverResetBtn}>Send</button>
        </div>
        }
            

        
    </div>
  )
}

export default DriversResertPassWord