import React, { useState } from 'react'
import "./CompanyLogin.css"
import { useNavigate } from 'react-router-dom'
import {useDispatch} from "react-redux"
import { companySignup } from '../../../../actions/authActions'
import Swal from 'sweetalert2'


const CompanySignUpPage1 = () => {

  const [formData, setFormData] = useState({
    companyName: "",
    companyAcronym: "",
    email:"",
    password:"",
    confirmPassword:""
  })
  const [loading, setLoading] = useState(false)
  const [continueBtn, setContinueBtn] = useState(true)
  const [nextBtn, setNextBtn] = useState(false)
  const [signupBtn, setSignupBtn] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateForm =()=>{
    if(!formData.email){
      Swal.fire('Error', 'Email is required', 'error');
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      Swal.fire('Error', 'Enter a valid email', 'error');
      return false;
    }
    return true;
  };
  const validateForm1 = () =>{
    if(!formData.companyName){
      Swal.fire('error', 'Company Name is required',  'error');
      return false;
    }
    if (!formData.companyAcronym){
      Swal.fire('error', 'company accronym is required', 'error');
      return false;
    }
    return true
  }

  const validateForm2= ()=>{
    if (!formData.password) {
      Swal.fire('Error', 'Password is required', 'error');
      return false;
    }
    if (formData.confirmPassword !== formData.password){
      Swal.fire('error', "password does not match", 'error');
      return false;
    }
    return true
  }


  const handleChange = (e)=>{
   setFormData({
    ...formData,
    [e.target.name]:e.target.value,
   });
  };

  const handleContinueBtn = ()=>{
    if(validateForm()){
      setNextBtn(true)
      setContinueBtn(false)
      setSignupBtn(false)
    }
   

    // if (formData.email){
    //   setNextBtn(true)
    //   setContinueBtn(false)
    //   setSignupBtn(false)
    // } else {
    //   alert('Enter Company Name to continue')
    // }
    
    
  }

  const handleNextBtn = ()=>{
    if(validateForm1()){
      setNextBtn(false)
      setSignupBtn(true)
    }
    
  }


  const handleContinue = (e)=>{
    e.preventDefault();
    if(validateForm2()){
      setLoading(true)
      dispatch(companySignup(formData))
      .finally(()=>setLoading(false));
      navigate('/companylogin')
    }
    
  }

  return (
   <form onSubmit={handleContinue}>
     <div className='comapnyLogin-container'>
      {continueBtn&&
          <div className='company-login-content'>
          <h2>Create your account</h2>
          <p>Create an account to list your transport company</p>
          <div className='login-email-holder'>
            <label>Email</label>
            <input type="email"  name="email" value={formData.email} onChange={handleChange} />
          </div>
          <button type='button' onClick={handleContinueBtn}> Continue</button>
          <div className='terms-and-condtion-holder'>
            <input type="checkbox" /><label>I agree to <span>Terms</span> and <span>Privacy Policy</span></label>
          </div>
          <div className='already-have-account'>
          <p>Already have an account? <span onClick={() => navigate('/companylogin')}>Login</span></p>
          </div>
        </div>
}
        {
          nextBtn&& 
           <div className='company-login-content'>
          <h2>Contact Details</h2>
          <p>Your Company name and phone number are needed to ensure the security of your Booking.com account.</p>
          <div className='login-email-holder'>
            <label>Company Name</label>
            <input type="text" name='companyName' value={formData.companyName} onChange={handleChange} />
          </div>
          <div className='login-email-holder'>
            <label>Company Acronym</label>
            <input type="text" name='companyAcronym' value={formData.companyAcronym} onChange={handleChange} />
          </div>
          {/* <div className='login-email-holder2'>
            <label>Phone Number</label>
            <div className='select-number-input'>
              <select name="234" id="">
              <option value="234">234</option>
              </select>
            <input type="text" />
            </div>
          </div> */}
          <button type='button'onClick={handleNextBtn} > Next</button>
         
        </div>
        } {signupBtn&&
           <div className='company-login-content'>
           <h2>Create Password</h2>
           <h5>Use a minimum of 10 characters, including uppercase letters, lowercase letters and numbers.</h5>
           <div className='login-email-holder' >
             <label>Password</label>
             <input type="password" placeholder='password' name='password' value={formData.password} onChange={handleChange}/>
           </div>
           <div className='login-email-holder'>
             <label>Comfirm Password</label>
             <input type="password" placeholder='comfirm password' name='confirmPassword' value={formData.confirmPassword} onChange={handleChange}/>
           </div>
           
           <button type='submit' disabled={loading} > {loading? 'loading... ':'Create account'}</button>
          
         </div>
      }
      

      

     
    </div>
   </form>
  )
}

export default CompanySignUpPage1
