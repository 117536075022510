import React from 'react'
import searchIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/search.svg"
import filterIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/filter.svg"
import navIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/nav.svg"
import doubleArrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-left.svg"
import arrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-left.svg"
import arrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-right.svg"
import doubleArrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-right.svg"
import driverImg from '../../images/driverImg.png'
import companyBus from "../../images/companyBus.png"

const CompanyDrivers = () => {
  return (
    <div className='companyVehicles-mainContainer'>
        <div className="company-dash-toplayer"></div>
      <>
       <h2>Vehicles</h2>
       <div className='company-tickets-maincontainer'>
     <div className='clientManagement-history'>
<div className='clientManagement-history-searchbar'>
<input type="text" />
<img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
<div className='filterContainer'> 
<img className='filterContainer-img' src={filterIcon} alt="" />
 
</div>
</div>
<div className='driverList-label-holder'>
<div className='vehicle-image-label'></div>
<p className='vehicle-label'>Driver's  ID</p>
<p className='vehicle-label'>Full Name</p>
<p className='vehicle-label'>Date Joined</p>
<div className="vehicle-menuHolder"></div>


</div>
<div className='vehicle-list-holder'>
<div className='vehicle-list-item'>
   <div className='vehicle-image2'>
       <img src={driverImg} alt="" />
   </div>
   <p className='vehicle-label'>GIGD-223909 </p>
   <p className='vehicle-label'>Gabriel Doe</p>
   <p className='vehicle-label'>04 Oct 2023</p>
 <div className='vehicle-menuHolder'>
 <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" />
 </div>
</div>

</div>
<div className='clientManagement-history-navigation'>
<div className='clientManagement-history-item'>
 <img src={doubleArrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2'>
 <img src={arrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2-active'>
 <p>1</p>
</div>
<div className='clientManagement-history-item2'>
 <p>2</p>
</div>
<div className='clientManagement-history-item2'>
 <img src={arrowRight} alt="" />
</div>
<div className='clientManagement-history-item3'>
 <img src={doubleArrowRight} alt="" />
</div>

</div>

</div>
     </div>
       </>
    </div>
  )
}

export default CompanyDrivers