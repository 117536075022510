import React from 'react'
import "./ContactInfo.css"
import DashboardHeader from '../../components/DashboardHeader'
import fb from "../../pages/dashContent/images/fb.svg"
import ig from "../../pages/dashContent/images/instagram.svg"
import linkin from "../../pages/dashContent/images/linkedinIn.svg"
import twitter from "../../pages/dashContent/images/twitter.svg"
import Footer from "../../components/Footer"


const ContactInfo = () => {

  return (
    <div className='contact-info-mainContainer'>
      <div className='contentHeader-container'>
        <DashboardHeader/>
      </div>
      <div className='contact-info-content'>
        <h3>Contact Information</h3>
        <div className='contact-info-item'>
          <div className='contact-info-item-title'>Contact Information</div>
          <div className='contact-info-inputfill-holder'>
          <div className='contact-info-item-list'>
            <label >Phone number</label>
            <input type="text" placeholder='+234 811 234545' />
          </div>
          <div className='contact-info-item-list'>
            <label >Email</label>
            <input type="text" placeholder='contact@vadtrans.com.ng' />
          </div>
          </div>
        </div>
        <div className='contact-info-item'>
          <div className='contact-info-item-title'>Address & Map</div>
          <div className='contact-info-inputfill-holder'>
          <div className='contact-info-item-list2'>
            <label >Address</label>
            <input type="text" placeholder='46, Amos Olagboyin Street, Ikeja, Lagos' />
          </div>
          
          </div>
        </div>
        <div className='contact-info-item3'>
          <div className='contact-info-item-title3'>Social Media Links</div>
          <div className='contact-info-socialMedia-item-holder'>
          <div className='contact-inf0-socialMedia-item'> 
            <label>Facebook</label>
            <div className='contact-info-socialMedia-input'>
              <input type="text" />
              <img src={fb} alt="facebook icon" />
            </div>
          </div>
          <div className='contact-inf0-socialMedia-item'> 
            <label>Twitter</label>
            <div className='contact-info-socialMedia-input'>
              <input type="text" />
              <img src={twitter} alt="twitter icon" />
            </div>
          </div>
          </div>
          <div className='contact-info-socialMedia-item-holder'>
          <div className='contact-inf0-socialMedia-item'> 
            <label>Instagram</label>
            <div className='contact-info-socialMedia-input'>
              <input type="text" />
              <img src={ig} alt="instagram icon" />
            </div>
          </div>
          <div className='contact-inf0-socialMedia-item'> 
            <label>LinkedIn</label>
            <div className='contact-info-socialMedia-input'>
              <input type="text" />
              <img src={linkin} alt="LinkedIn icon" />
            </div>
          </div>
          </div>
          
        </div>
        <button>Complete</button>
    
      </div>
      <div className='footer'>
          <Footer/>
      </div>
      
    </div>
  )
}

export default ContactInfo
