import React from 'react'
import "./card1.css"
import company1 from "../images/company1.png"
import { IoMdStar } from "react-icons/io";

const Card1 = () => {
  return (
    <div className='card1-Holder'>
      <div className="card1-imgHolder">
        <img src={company1} alt="" />
      </div>
      <div className="card1-details">
          <h2 className='card-details-title'>GUO Transport Company</h2>
          <p className='card-details-destination'>Destinations : Lagos, Abuja, Port Harcourt, Ibadan,
            Enugu </p>
          <p className='card-details-price'>Starting from #11,500</p>
          <p className='card-details-rating'>4.6 <IoMdStar /> </p>
      </div>
    </div>
  )
}

export default Card1
