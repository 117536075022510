import React, { useState } from 'react'
import "./travellerEditProf.css"
import { FaArrowLeft } from "react-icons/fa";
import user6 from "../../TravellerSide/images/profile.jpg"
import { MdOutlinePhotoCamera } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '../../../actions/authActions';

const TravellerEditProfile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state)=>state.auth.userData)
  const token = useSelector((state)=>state.auth.token)
  const userId = useSelector((state)=>state.auth.userId)

  const [formData, setFormData]= useState({
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    gender: userData?.gender || '',
    title: userData?.title || '',
    dateOfBirth: userData?.dateOfBirth || '',
    phoneNumber: userData?.phoneNumber || '',
    // email: userData?.email || '',
    address: userData?.address || '',
    city: userData?.city || '',
    // profilePicture: null, // For handling image uploads
  })

  const [loading, setLoading] = useState(false)

  const goBack = () => {
    window.history.back();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      profilePicture: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    const updatedData = new FormData();
    
    for (let key in formData) {
      updatedData.append(key, formData[key]);
    }
    dispatch(updateUserData(updatedData, 'traveller', token))
    .finally(() => setLoading(false));

  };



  return (
    <div className="TraveEditProfile-main">
      <div className="editProfile-container">
        <div className="editProfile-top">
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack}/>
          <h3>Edit Profile</h3>
        </div>
        <form onSubmit={handleSubmit} className="editProfile-btm">
        
          <div className="editProfile-btm-left">
                  <img src={user6} alt="" />
                  <div className="changeProPics">
                  <MdOutlinePhotoCamera color='white'/>
                    <input className='fileChange-holder'  type='file' onChange={handleFileChange}/> 
                  </div>
          </div>
          <div className="editProfile-btm-right">
              <div className="editProfile-btm-right-sec1">
                <div className="editProfile-btm-right-sec1a">
                  <label htmlFor="">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="editProfile-btm-right-sec1a">
                  <label htmlFor="">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="editProfile-btm-right-sec12">
                  <div className="editProfile-btm-right-sec12a">
                    <label htmlFor="">Gender</label>
                    <div className="gender-option">
                          <div className="gender-option-a">
                          <input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={formData.gender === 'male'}
                        onChange={handleInputChange}
                      />
                          <label htmlFor="">male</label>
                          </div>
                          <div className="gender-option-a">
                          <input
                        type="radio"
                        name="gender"
                        value="female"
                        checked={formData.gender === 'female'}
                        onChange={handleInputChange}
                      />
                          <label htmlFor="">female</label>
                          </div>
                    </div>
                  </div>
                  <div className="editProfile-btm-right-sec12b">
                    <label htmlFor="">Title</label>
                    <select name="title" value={formData.title} onChange={handleInputChange}>
                      <option value="" disabled selected>select</option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                    </select>
                  </div>
                  <div className="editProfile-btm-right-sec12c">
                    <label htmlFor="">Date of Birth</label>
                    <input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                  />
                  </div>
              </div>
              <div className="editProfile-btm-right-sec1">
                <div className="editProfile-btm-right-sec1a">
                  <label htmlFor="">Mobile Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="editProfile-btm-right-sec1a">
                  <label htmlFor="">Email Address</label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="editProfile-btm-right-sec1">
                <div className="editProfile-btm-right-sec13a">
                  <label htmlFor="">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="editProfile-btm-right-sec13b">
                  <label htmlFor="">City</label>
                  <select name="city" value={formData.city} onChange={handleInputChange}>
                    <option value="" selected disabled>Select</option>
                    <option value="Lagos">Lagos</option>
                    <option value="Oyo">Oyo</option>
                    <option value="Abuja">Abuja</option>
                    <option value="PortHarcout">PortHarcourt</option>
                    <option value="Rivers">Rivers</option>
                  </select>
                </div>
                
              </div>
              <div className="editProfile-btm-right-sec1">
                <button type='submit'>{loading ? 'saving...' : 'save'}</button>
              </div>
          </div>
        
        </form>
      </div>
    </div>
  )
}

export default TravellerEditProfile
