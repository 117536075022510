import React, { useState } from 'react'
import "./Faqs.css"
import DashboardHeader from '../../components/DashboardHeader'
import searchIcon from "../dashContent/images/search.svg"
import filterIcon from "../dashContent/images/filter.svg"
import navIcon from "../dashContent/images/nav.svg"
import Footer from "../../components/Footer"

const Faqs = () => {
const [createFaqs, setCreateFaqs] = useState(true)
 


  const handleCreateFaqs=()=>{
   setCreateFaqs(false)
  }

  const handleComplete=()=>{
    setCreateFaqs(true)
  }
  return (
    <div className='fags-main-Container'>
       <div className='fagsHeader-container'>
        <DashboardHeader/>
      </div>

      {createFaqs ?
      
      
      <div className='faqs-container'>
      <div className='faqs-title'>
        <h3>FAQs</h3>
        <div className='faqs-button'>
          <img src="" alt="" />
          <button onClick={handleCreateFaqs}>Add New</button>
        </div>
      </div>
      <div className='fag-questions-holder'>
          <div className='faqs-searchbar'>
            <input type="text" />
            <img src={searchIcon} alt="" />
            <div className='filterContainer'> 
            <img src={filterIcon} alt="" />
              
            </div>
          </div>
          <div className='faqs-labels'>
            <p>Questions</p>
            <p> Date Created</p>
            
          </div>
          <div className='faqs-holder'>
            <div className='faqs-list-item'>
              <p>How can I use VadTrans?</p>
              <p>04 Oct 2023 | 12:18 pm</p>
              <img src={navIcon} alt="nav Icon" />
            </div>
            
            
          </div>
        </div>
      </div>
      
      
      :  <div className='faqs-container2'>
      <div className='faqs-title'>
        <h3>Add FAQs</h3>
       </div>
       <div className='faqs-question-input-holder'> 
        <label>Question</label>
        <input type="text" />
       </div>
       <div className='faqs-answer-input-holder'> 
        <label>Answers</label>
        <input type="text" />
       </div>
       <div className='faqs-complete-btnHolder'> <button onClick={handleComplete}>Complete</button></div>
      </div>

      }
      
        <div className='faqs-footer'>
          <Footer/>
        </div>
    </div>
  )
}

export default Faqs
