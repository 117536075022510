import React from 'react'
import "./CompanyTracking.css"

const CompanyTracking = () => {
  return (
    <div className='company-tracking-container'>
      <div className='company-dash-toplayer'></div>
      <div className="company-tracking-searchHolder">
        <label>Track a Trip</label>
        <input type="text" name="" id="" placeholder='Enter Ticket ID'/>
      </div>
      <div className="company-tracking-tripDetails">
        <label>Trip Details</label>
        <p>Ticket ID : GIGM286GD</p>
      </div>
    </div>
  )
}

export default CompanyTracking
