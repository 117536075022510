import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./headerTraveller.css";
import phoneRed from "../images/phoneRed.svg";
import envelope from "../images/envelope.svg";
import logoA from "../images/logo.svg";
import searchIcon from "../images/search.svg";
import { useNavigate } from 'react-router-dom';
import menuBar from "../../TravellerSide/images/MenuBar.svg";
import notFyIcon from "../images/notFyIcon.svg";
import user4 from "../../Company/images/user2.svg";
import { FaAngleDown } from "react-icons/fa6";
import { fetchUserData, logout } from '../../../actions/authActions';

const HeaderTraveller = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userProfileOption, setUserProfileOption] = useState(false);
    const [menuIsTrue, setMenuIsTrue] = useState(false);

    const userData = useSelector((state) => state.auth.userData);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('token');

            console.log('UserID from localStorage:', userId);
            console.log('Token from localStorage:', token);

            if (userId && token) {
                dispatch(fetchUserData(userId, token, 'traveler'))
                    .catch((error) => {
                        console.error('Failed to fetch user data:', error);
                    });
            } else {
                console.warn('UserID or Token not found in localStorage.');
            }
        }
    }, [dispatch, isAuthenticated]);

    useEffect(() => {
        if (userData) {
            console.log('Fetched User Data:', userData);
        }
    }, [userData]);

    const handleListItemBtn = () => {
        navigate("/companyside");
        setMenuIsTrue(false);
    };

    const handleProfileBtn = () => {
        navigate('/userprofile');
        setMenuIsTrue(false);
    };

    const handleUserProfileOptionBtn = () => {
        // navigate('/editprofile')
        setUserProfileOption(!userProfileOption);
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    return (
        <div className='header-traveller-main'>
            <div className='Traveller-header-top'>
                <div className='Traveller-header-top-item'>
                    <img src={phoneRed} alt="phone" />
                    <p>+234-812-945-0899</p>
                </div>
                <div className='Traveller-header-top-item'>
                    <img src={envelope} alt="envelope" />
                    <p>contact@vadtrans.com.ng</p>
                </div>
                <div className="traveller-button-holder">
                    <button>Follow Us</button>
                </div>
            </div>
            <div className='traveller-header-bottom'>
                <div className="traveller-header-bottom-item1">
                    <img src={logoA} alt="logo" />
                </div>
                <div className="traveller-header-bottom-item2">
                    <p className='activeP' onClick={() => navigate("/")}>Home</p>
                    <p onClick={() => navigate('/aboutus')}>About Us</p>
                    <p onClick={() => navigate("/transportTracking")}>Tracking</p>
                    <p onClick={() => navigate('/contactus')}>Contact Us</p>
                </div>
                {isAuthenticated ? (
                    <div className="traveller-header-bottom-item3">
                        <div className="travelle-header-sec3Items">
                            <img src={notFyIcon} alt="notification" onClick={() => navigate('/mynotifications')} />
                        </div>
                        <div className="travelle-header-sec3Items2">
                            <img className="travelle-header-sec3Items2-img" src={user4} alt="user" />
                            <p onClick={handleProfileBtn}>{userData?.data.
                            
                            firstName || 'User'}</p>
                            <FaAngleDown onClick={handleUserProfileOptionBtn} />
                        </div>
                        <div className="travelle-header-sec3Items">
                            <img src={searchIcon} alt="search" />
                        </div>
                    </div>
                ) : (
                    <div className="traveller-header-bottom-item3">
                        <button className="traveller-header-1" onClick={handleListItemBtn}>List your Company</button>
                        <button className="traveller-header-button-2" onClick={() => navigate("/login")}>Login</button>
                        <button className="traveller-header-button-3" onClick={() => navigate("/signup")}>Signup</button>
                        <img src={searchIcon} alt="search" />
                    </div>
                )}
                <div className="traveller-header-bottom-item4">
                    <img src={menuBar} alt="menu" onClick={() => setMenuIsTrue(!menuIsTrue)} />
                </div>
            </div>
            {
                userProfileOption&& (
                    <div className='userProfile-option-holder'>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={handleProfileBtn}>Profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={() => navigate('/editprofile') }>Edit profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={handleLogout}>Logout</p>
                                </div>
                            </div>
                )
            }
            {menuIsTrue && (
                <div className='menuItems-lists-Holder'>
                    <div className="menuItems-lists-Holder-topSec">
                        <div className='menuItems-lists-Holder-topSec-item'>
                            <img src={phoneRed} alt="phone" />
                            <p>+234-812-945-0899</p>
                        </div>
                        <div className='menuItems-lists-Holder-topSec-item'>
                            <img src={envelope} alt="envelope" />
                            <p>contact@vadtrans.com.ng</p>
                        </div>
                        <div className="menuItems-lists-Holder-topSec-item-button-holder">
                            <button>Follow Us</button>
                        </div>
                    </div>
                    <div className="menuItems-lists-Holder-btmSec">
                        <div className="menuItems-lists-Holder-btmSec-item1">
                            <div className="menuItems-lists-Holder-btmSec-srchBtn">
                                <img src={searchIcon} alt="search" /><input type="text" placeholder='search' />
                            </div>
                        </div>
                        <div className="menuItems-lists-Holder-btmSec-item2-active">
                            <p onClick={() => navigate("/")}>Home</p>
                        </div>
                        <div className="menuItems-lists-Holder-btmSec-item2">
                            <p onClick={() => navigate('/aboutus')}>About us</p>
                        </div>
                        <div className="menuItems-lists-Holder-btmSec-item2">
                            <p onClick={() => navigate("/transportTracking")}>Tracking</p>
                        </div>
                        <div className="menuItems-lists-Holder-btmSec-item2">
                            <p onClick={() => navigate('/contactus')}>Contact us</p>
                        </div>
                        {userData ? (
                            <>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={handleProfileBtn}>Profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={handleUserProfileOptionBtn}>Edit profile</p>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p onClick={handleLogout}>Logout</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="menuItems-lists-Holder-btmSec-item3">
                                    <button onClick={handleListItemBtn}>List your Company</button>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item3">
                                    <button onClick={() => navigate("/login")}>Login</button>
                                </div>
                                <div className="menuItems-lists-Holder-btmSec-item3">
                                    <button onClick={() => navigate("/signup")}>Signup</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeaderTraveller;
