import React from "react";
import "./tranportcompanies.css"
import { IoMdSearch } from "react-icons/io";
import { MdFilterAlt } from "react-icons/md";
import companyLogo from"../../images/company1.png"
import { MdStarRate } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const TransportCompanies = ()=>{
    const navigate = useNavigate()

    const handleCompanyDetails = ()=>{
        navigate('/transportcompanydetails')
    }
    return (
        <div className="traveleerSide-ttransporcompanies-container">
            <div className="traveleerSide-ttransporcompanies-topsec">
                <h2>TRANSPORT COMPANIES</h2>
            </div>
            <div className="traveleerSide-ttransporcompanies-item1">
                <div className="traveleerSide-ttransporcompanies-item1a">
                     <IoMdSearch />
                    <input type="text" placeholder="search" />
                </div>
                <div className="traveleerSide-ttransporcompanies-item1a">
                     <MdFilterAlt />
                    <select name="" id="">
                        <option value="" >Filter</option>
                        <option value="">Time</option>
                        <option value="">Location</option>
                    </select>

                </div>
            </div>
            <div className="traveleerSide-ttransporcompanies-item-container">
               
                <div className="traveleerSide-ttransporcompanies-item-container-items">
                        <img src={companyLogo} alt="" />
                        <div className="traveleerSide-ttransporcompanies-item-container-items1a">
                            <div className="ratingHolder">
                                4.5  <MdStarRate />
                            </div>
                            <h3>GUO Transport Company</h3>
                            <p>Destinations : Lagos, Abuja, Port Harcourt, Ibadan, Enugu </p>
                            <h6>Starting from # 11,500</h6>
                            <div className="tansportCompanies-btnHolder">
                                <button onClick={handleCompanyDetails}>View More</button>
                            </div>
                        </div>
                        <div className="traveleerSide-ttransporcompanies-item-container-items1b" >
                             <div className="tansportCompanies-btnHolder2">4.3 <MdStarRate /></div>
                    <button onClick={handleCompanyDetails}>View</button></div>
                </div>
                <div className="traveleerSide-ttransporcompanies-item-container-items">
                        <img src={companyLogo} alt="" />
                        <div className="traveleerSide-ttransporcompanies-item-container-items1a">
                            <div className="ratingHolder">
                                4.5  <MdStarRate />
                            </div>
                            <h3>GUO Transport Company</h3>
                            <p>Destinations : Lagos, Abuja, Port Harcourt, Ibadan, Enugu </p>
                            <h6>Starting from # 11,500</h6>
                            <div className="tansportCompanies-btnHolder">
                                <button>View More</button>
                            </div>
                        </div>
                        <div className="traveleerSide-ttransporcompanies-item-container-items1b" >
                             <div className="tansportCompanies-btnHolder2">4.3 <MdStarRate /></div>
                    <button>View</button></div>
                </div>
                <div className="traveleerSide-ttransporcompanies-item-container-items">
                        <img src={companyLogo} alt="" />
                        <div className="traveleerSide-ttransporcompanies-item-container-items1a">
                            <div className="ratingHolder">
                                4.5  <MdStarRate />
                            </div>
                            <h3>GUO Transport Company</h3>
                            <p>Destinations : Lagos, Abuja, Port Harcourt, Ibadan, Enugu </p>
                            <h6>Starting from # 11,500</h6>
                            <div className="tansportCompanies-btnHolder">
                                <button>View More</button>
                            </div>
                        </div>
                        <div className="traveleerSide-ttransporcompanies-item-container-items1b" >
                             <div className="tansportCompanies-btnHolder2">4.3 <MdStarRate /></div>
                    <button>View</button></div>
                </div>
                <div className="traveleerSide-ttransporcompanies-item-container-items">
                        <img src={companyLogo} alt="" />
                        <div className="traveleerSide-ttransporcompanies-item-container-items1a">
                            <div className="ratingHolder">
                                4.5  <MdStarRate />
                            </div>
                            <h3>GUO Transport Company</h3>
                            <p>Destinations : Lagos, Abuja, Port Harcourt, Ibadan, Enugu </p>
                            <h6>Starting from # 11,500</h6>
                            <div className="tansportCompanies-btnHolder">
                                <button onClick={handleCompanyDetails}>View More</button>
                            </div>
                        </div>
                        <div className="traveleerSide-ttransporcompanies-item-container-items1b" >
                             <div className="tansportCompanies-btnHolder2">4.3 <MdStarRate /></div>
                    <button onClick={handleCompanyDetails}>View</button></div>
                </div>
                
               
            </div>
            <div className="travellerTicket-navigation">
          <div className="navBack">
          <MdOutlineKeyboardArrowLeft />
          </div>
          <div className="navPageNumber-holder">
            <p>1</p>
          </div>
          <div className="navForward">
          <MdOutlineKeyboardArrowRight />
          </div>
        </div>
        </div>
    )
}
export default TransportCompanies;