import React, { useState } from 'react'
import "./Clientmanament.css"
import DashboardHeader from '../../components/DashboardHeader'
import searchIcon from "../dashContent/images/search.svg"
import filterIcon from "../dashContent/images/filter.svg"
import navIcon from "../dashContent/images/nav.svg"
import doubleArrowLeft from "../dashContent/images/angle-double-left.svg"
import arrowLeft from "../dashContent/images/angle-left.svg"
import arrowRight from "../dashContent/images/angle-right.svg"
import doubleArrowRight from "../dashContent/images/angle-double-right.svg"
import GUO from "../../pages/dashContent/images/guo.svg"
import GUO2 from "../../pages/dashContent/images/GUO-bold.png"
import Footer from '../../components/Footer'
import motor1 from "../../pages/dashContent/images/motor1.png"
import motor2 from "../../pages/dashContent/images/motor2.png"
import motor3 from "../../pages/dashContent/images/motor3.png"
import FB from "../dashContent/images/fb.svg"
import IG from "../dashContent/images/instagram.svg"
import TW from "../dashContent/images/twitter.svg"





const ClientManagement = () => {

  const [company, setCompany] = useState(false)
  const [customers, setCustomers]= useState(true)
  const [clientManagement, setClientManagement] = useState(true)
  const [aboutClientManagement, setAboutClientManagement] = useState(false)

  console.log(company)
  console.log(aboutClientManagement)

  const handleAboutClientManagement=()=>{
    setAboutClientManagement(true)
    setClientManagement(false)
  }

  const handleComapny=()=>{
    setCompany(true)
    setCustomers(false)
  }
  const handleCustomer=()=>{
    setCompany(false)
    setCustomers(true)
  }

  return (
    <div className='clientManagement-main-container'>
      <div className='clientManagementHeader-container'>
        <DashboardHeader/>
      </div>
      {clientManagement ? 
      
      <div className='clientManagement-container'>
      <div className='clientManagement-Header'>All Clients</div>
      <div className='clientManagement-Header-holder'>
        <button className='clientmanager-btn-active' onClick={handleCustomer}>Customers</button>
        <button className='clientmanager-btn' onClick={handleComapny}>Transport Companies</button>
      </div>
      {customers? 

<div className='clientManagement-history'>
<div className='clientManagement-history-searchbar'>
<input type="text" />
<img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
<div className='filterContainer'> 
<img className='filterContainer-img' src={filterIcon} alt="" />
  
</div>
</div>
<div className='clientManagement-history-labels'>
<p>Name</p>
<p>Email Address</p>
<p>Date Created</p>

</div>
<div className='clientManagement-list-holder'>
<div className='clientManagement-list-item'>
  <p>Jane Doe</p>
  <p>janedoe@gmail.com</p>
  <p>04 Oct 2023 | 23:18</p>
  <div className='clientManagement-list-item-img2-container'>
  <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" />
  </div>
</div>

</div>
<div className='clientManagement-history-navigation'>
<div className='clientManagement-history-item'>
  <img src={doubleArrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2-active'>
  <p>1</p>
</div>
<div className='clientManagement-history-item2'>
  <p>2</p>
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowRight} alt="" />
</div>
<div className='clientManagement-history-item3'>
  <img src={doubleArrowRight} alt="" />
</div>

</div>

</div>
      :
      <div className='clientManagement-history'>
        <div className='clientManagement-history-searchbar'>
          <input type="text" />
          <img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
          <div className='filterContainer'> 
          <img className='filterContainer-img' src={filterIcon} alt="" />
            
          </div>
        </div>
        <div className='clientManagement-history-labels2'>
          <div className='company-name-holder'> 
          <div className='company-name'>Company Name</div>
          </div>
          <div className='Company-date'>Date Created</div>
          <div className='company-status'>Status </div>
          
        </div>
        <div className='clientManagement-list-holder'>
          <div className='clientManagement-list-item2'>
          <div className='company-name-holder2'> 
          <img src={GUO} alt="" />
            <div className='company-name2'>GUO Transport Company</div>
          </div>
          <div className='Company-date'>04 Oct 2023 | 23:18</div>
          <div className='clientManagement-list-item-btn-holder'>
                 <button>Approved</button>
              </div>
            <div className='clientManagement-list-item-img2-container'>
            <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" onClick={handleAboutClientManagement}/>
            </div>
          </div>
          
        </div>
        <div className='clientManagement-history-navigation'>
          <div className='clientManagement-history-item'>
            <img src={doubleArrowLeft} alt="" />
          </div>
          <div className='clientManagement-history-item2'>
            <img src={arrowLeft} alt="" />
          </div>
          <div className='clientManagement-history-item2-active'>
            <p>1</p>
          </div>
          <div className='clientManagement-history-item2'>
            <p>2</p>
          </div>
          <div className='clientManagement-history-item2'>
            <img src={arrowRight} alt="" />
          </div>
          <div className='clientManagement-history-item3'>
            <img src={doubleArrowRight} alt="" />
          </div>

      </div>
        
      </div>}

      
     
    </div>
    :
        
       <div className='clientManagement-container2'>
      <div className='clientManagement-Header'>Details about Company</div>
        <div className='abount-company-image-holder'>
        <img  src={GUO2} alt="" />
        </div>
        <div className='comany-name-input'>
          <label >Company Name</label>
          <input type="text" placeholder=''/>
        </div>
        <div className='comany-about-input'>
          <label >About Company</label>
          <input type="text" placeholder=''/>
        </div>
        <div className='company-image-holders'>
          <img src={motor1} alt="" />
          <img src={motor2} alt="" />
          <img src={motor3} alt="" />
        </div>
        <div className='socialMedia-Handler-holder'>
            <div className='socialMedia-handlers-item'>
              <label>Facebook</label>
              <div className='socialMedia-handlers'>
                <input type="text" />
                <img src={FB} alt="" />
              </div>
            </div>
            <div className='socialMedia-handlers-item'>
              <label>Instagram</label>
              <div className='socialMedia-handlers'>
                <input type="text" />
                <img src={IG} alt="" />
              </div>
            </div>
            <div className='socialMedia-handlers-item'>
              <label>Twitter</label>
              <div className='socialMedia-handlers'>
                <input type="text" />
                <img src={TW} alt="" />
              </div>
            </div>
        </div>
        <div className='approval-btn'>
          <button className='approve-btn'>Approve</button>
          <button className='Decline-btn'>Decline</button>
          <button className='Suspend-btn'>Suspend</button>
          <button className='delete-btn'>Delete</button>
          '
        </div>
       </div>

      }
      
      <div className='clientManagement-footer'>
          <Footer/>
      </div>
    </div>
  )
}

export default ClientManagement
