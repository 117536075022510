import React from 'react'
import "./aboutTraveller.css"
import contactImg2 from "../../images/contactImg2.jpg"
import profilePics  from "../../images/profile.jpg"

const TravellerAboutUs = () => {
  return (
    <div className='traveller-aboutUs'>
      <div className="traveller-aboutUs-main">
        <div className="traveller-aboutUs-topSection">
          <h1>ABOUT US</h1>
        </div>
        <div className='traveller-aboutus-section2'>
              <div className="traveller-aboutus-section2-leftside">
                <p>Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.. Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                <p> Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                <p>Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.. Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
              </div>
              <div className="traveller-aboutus-section2-rightside">
                <img src={contactImg2} alt="" />
              </div>
        </div>
        <div className="traveller-aboutus-section3">
            <div className="traveller-aboutus-section3-leftside">
              <img src="" alt="" />
              <div className='tav-abo-sec3-title'>
                <h3>Our Mission</h3>
                <div className='p-line-holder'>
                   <p className='p-line1'></p>
                   <p className='p-line2'></p>
                   <p className='p-line3'></p></div>
                </div>  
                <p className='abou-sec3-contentText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.</p>            

            </div>
            <div className="traveller-aboutus-section3-leftside">
              <img src="" alt="" />
              <div className='tav-abo-sec3-title'>
                <h3>Our Mission</h3>
                <div className='p-line-holder'>
                   <p className='p-line1'></p>
                   <p className='p-line2'></p>
                   <p className='p-line3'></p></div>
                </div>  
                <p className='abou-sec3-contentText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.</p>            

            </div>
            
        </div>
        <div className="traveller-about-section4">
          <div className="traveller-about-section4-leftside">
                  <h5>Meet Our Team</h5>
                  <h3>Management Teams </h3>
          </div>
          <div className="traveller-about-section4-rightside">
            <div className="traveller-teamCard">
                <img src={profilePics} alt="" />
                <div className='traveller-team'>
                  <h3>Abimbola Taiwo</h3>
                  <h5>Accountant</h5>
                </div>
            </div>
            <div className="traveller-teamCard">
                <img src={profilePics} alt="" />
                <div className='traveller-team'>
                  <h3>Abimbola Taiwo</h3>
                  <h5>Accountant</h5>
                </div>
            </div>
            <div className="traveller-teamCard">
                <img src={profilePics} alt="" />
                <div className='traveller-team'>
                  <h3>Abimbola Taiwo</h3>
                  <h5>Accountant</h5>
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default TravellerAboutUs
