import React from 'react'
import "./Payment.css"
import DashboardHeader from '../../components/DashboardHeader'
import searchIcon from "../dashContent/images/search.svg"
import filterIcon from "../dashContent/images/filter.svg"
import navIcon from "../dashContent/images/nav.svg"
import doubleArrowLeft from "../dashContent/images/angle-double-left.svg"
import arrowLeft from "../dashContent/images/angle-left.svg"
import arrowRight from "../dashContent/images/angle-right.svg"
import doubleArrowRight from "../dashContent/images/angle-double-right.svg"

const Payment = () => {
  return (
    <div className='payment-main-container'>
      <div className='paymentHeader-container'>
        <DashboardHeader/>
      </div>
      <div className='payment-container'>
        <div className='payment-Header'>Payments</div>
        <div className='payments-history'>
          <div className='payment-history-searchbar'>
            <input type="text" />
            <img src={searchIcon} alt="" />
            <div className='filterContainer'> 
            <img src={filterIcon} alt="" />
              
            </div>
          </div>
          <div className='payment-history-labels'>
            <p>Payment ID</p>
            <p> Paid by</p>
            <p>Amount</p>
            <p>Date</p>
          </div>
          <div className='payment-list-holder'>
            <div className='payment-list-item'>
              <p>TF8286GD</p>
              <p>Jane Doe</p>
              <p># 12,000</p>
              <p>04 Oct 2023 | 23:18</p>
              <img src={navIcon} alt="nav Icon" />
            </div>
            
          </div>
          <div className='payment-history-navigation'>
            <div className='payment-history-item'>
              <img src={doubleArrowLeft} alt="" />
            </div>
            <div className='payment-history-item2'>
              <img src={arrowLeft} alt="" />
            </div>
            <div className='payment-history-item2-active'>
              <p>1</p>
            </div>
            <div className='payment-history-item2'>
              <p>2</p>
            </div>
            <div className='payment-history-item2'>
              <img src={arrowRight} alt="" />
            </div>
            <div className='payment-history-item3'>
              <img src={doubleArrowRight} alt="" />
            </div>

        </div>
          
        </div>
       
      </div>
    </div>
  )
}

export default Payment
