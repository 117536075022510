import "./CompanyDashboard.css"
import companyLogo from "../../images/logoW.svg"
import user1 from "../../images/user.svg"
import ticketicon from "../../images/tickets.svg"
import trackingicon from "../../images/tracking.svg"
import paymentIcon from "../../images/payment.png"
import uploadIcon from "../../images/plus.svg"
import { IoBusOutline } from "react-icons/io5";
import { FiUser } from "react-icons/fi";
import { TbBus } from "react-icons/tb";

import UserProfile from '../UserProfile/UserProfile'
import CompanyTracking from "../CompanySideTracking/CompanyTracking"
import CompanyTickets from "../CompanyTickets/CompanyTickets"
import CompanyUpload from "../CompanyUpload/CompanyUpload"
import CompanyPayment from "../CompanyPayments/CompanyPayment"
import { useState } from "react"
import CompanyVehicles from "../companyVehicles/CompanyVehicles"
import CompanyTrips from "../companyTrips/CompanyTrips"
import CompanyDrivers from "../companyDrivers/CompanyDrivers"

const CompanyDashboard = () => {
 const [companyUserProfile, setCompanyUserProfile]= useState(false)
 const [companyTickets, setCompanyTickets] = useState (false)
 const [companyTracking, setCompanyTracking] = useState(false)
 const [companyPayment, setCompanyPayment] = useState(false)
 const [companyUpload, setCompanyUpload] = useState (false)
 const [companyVehicles, setCompanyVehicles] = useState (false)
 const [companyDrivers, setCompanyDrivers] = useState(false)
 const [companyTrips, setCompanyTrips] =useState(true)

 const handleCompanyUserProfile = ()=>{
  setCompanyUserProfile(true)
  setCompanyPayment(false)
  setCompanyTickets(false)
  setCompanyTracking(false)
  setCompanyUpload(false)
  setCompanyDrivers(false)
  setCompanyVehicles(false)
  setCompanyTrips(false)
 }
 const handleCompanyTickets = ()=>{
  setCompanyUserProfile(false)
  setCompanyPayment(false)
  setCompanyTickets(true)
  setCompanyTracking(false)
  setCompanyUpload(false)
  setCompanyDrivers(false)
  setCompanyVehicles(false)
  setCompanyTrips(false)
 }

 const handleCompanyPayment = ()=>{
  setCompanyUserProfile(false)
  setCompanyPayment(true)
  setCompanyTickets(false)
  setCompanyTracking(false)
  setCompanyUpload(false)
  setCompanyDrivers(false)
  setCompanyVehicles(false)
  setCompanyTrips(false)
 }

 const handleCompanyTracking = ()=>{
  setCompanyUserProfile(false)
  setCompanyPayment(false)
  setCompanyTickets(false)
  setCompanyTracking(true)
  setCompanyUpload(false)
  setCompanyDrivers(false)
  setCompanyVehicles(false)
  setCompanyTrips(false)
 }

 const handleCompanyUpload = ()=>{
  setCompanyUserProfile(false)
  setCompanyPayment(false)
  setCompanyTickets(false)
  setCompanyTracking(false)
  setCompanyUpload(true)
  setCompanyDrivers(false)
  setCompanyVehicles(false)
  setCompanyTrips(false)
 }
 
 const handleCompanyDrivers = ()=>{
  setCompanyUserProfile(false)
  setCompanyPayment(false)
  setCompanyTickets(false)
  setCompanyTracking(false)
  setCompanyUpload(false)
  setCompanyDrivers(true)
  setCompanyVehicles(false)
  setCompanyTrips(false)
 }
 const handleCompanyTrips = ()=>{
  setCompanyUserProfile(false)
  setCompanyPayment(false)
  setCompanyTickets(false)
  setCompanyTracking(false)
  setCompanyUpload(false)
  setCompanyDrivers(false)
  setCompanyVehicles(false)
  setCompanyTrips(true)
 }
 const handleCompanyVehicles = ()=>{
  setCompanyUserProfile(false)
  setCompanyPayment(false)
  setCompanyTickets(false)
  setCompanyTracking(false)
  setCompanyUpload(false)
  setCompanyDrivers(false)
  setCompanyVehicles(true)
  setCompanyTrips(false)
 }





  return (
    <div className='companyside-dashboard-container'>
      <div className='comapnyside-sidebar-conatiner'>
          <div className='company-dash-toplayer'></div>
          <div className='company-sidebar-holder'>
            <img src={companyLogo} alt="" />
            <div className={!companyUserProfile? 'company-sidebar-item': "company-sidebar-item-active"} onClick={handleCompanyUserProfile}>
              <img src={user1} alt="" />
              <p>User Profile</p>
            </div>
            <div className={!companyTickets? 'company-sidebar-item': "company-sidebar-item-active"} onClick={handleCompanyTickets}>
              <img src={ticketicon} alt="" />
              <p>Tickets</p>
            </div>

            <div className={!companyTracking? 'company-sidebar-item': "company-sidebar-item-active"} onClick={handleCompanyTracking}>
              <img src={trackingicon} alt="" />
              <p>Tracking</p>
            </div>

            <div className={!companyPayment? 'company-sidebar-item': "company-sidebar-item-active"} onClick={handleCompanyPayment}>
              <img src={paymentIcon} alt="" />
              <p>Payments</p>
            </div>

            <div className={!companyUpload? 'company-sidebar-item': "company-sidebar-item-active"} onClick={handleCompanyUpload}>
              <img src={uploadIcon} alt="" />
              <p>Upload</p>
            </div>
            <div className={!companyVehicles? 'company-sidebar-item': "company-sidebar-item-active"} onClick={handleCompanyVehicles}>
            <IoBusOutline />
              <p>Vehicles</p>
            </div>
            <div className={!companyDrivers? 'company-sidebar-item': "company-sidebar-item-active"} onClick={handleCompanyDrivers}>
            <FiUser />
              <p>Drivers</p>
            </div>
            <div className={!companyTrips? 'company-sidebar-item': "company-sidebar-item-active"} onClick={handleCompanyTrips}>
            <TbBus />
              <p>Trips</p>
            </div>

          </div>
      </div>
      <>
      {
        companyUserProfile?(
      <UserProfile/>

        ): companyPayment ? (
      <CompanyPayment/>
        ): companyTickets? (
      <CompanyTickets/>

        ): companyTracking? (
      <CompanyTracking/>

        ): companyUpload?(
      <CompanyUpload/>

        ):companyVehicles?(
          <CompanyVehicles/>
        ):companyDrivers?(
          <CompanyDrivers/>
        ): companyTrips? (
          <CompanyTrips/>
        ):null

      }

      </>
      
    </div>
  )
}

export default CompanyDashboard
