import React, { useEffect } from 'react'
import "./CompanyHeader.css"
import companyLogo from "../images/logoW.svg"
import searcIcon from "../images/searchBlc.svg"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserData, logout } from '../../../actions/authActions'
import { FaAngleDown } from "react-icons/fa6";
import notification from "../images/Notification.png"

// import searchIcon from "../../DriverSide/Component/"

const CompanyHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state)=> state.auth.userData);
  const  isAuthenticated = useSelector ((state)=>state.auth.isAuthenticated);

  useEffect(()=>{
    if (isAuthenticated) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      console.log('UserID from localStorage:', userId);
      console.log('Token from localStorage:', token);

      if (userId && token) {
          dispatch(fetchUserData(userId, token, 'transport_company'))
              .catch((error) => {
                  console.error('Failed to fetch user data:', error);
              });
      } else {
          console.warn('UserID or Token not found in localStorage.');
      }
  }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (userData) {
        console.log('Fetched User Data:', userData);
    }
}, [userData]);


  const handleLoginasCompany=()=>{
    navigate("./companylogin")
  }
  const handleNotificationIcon=()=>{
    navigate('./notifications')
  }
  return (
    <div className='companyHeader-container'>
      <img src={companyLogo} alt="" />

      {isAuthenticated ? 
      <div className="traveller-header-bottom-item3">
          <p>Contact us</p>
      <div className="travelle-header-sec3Items">
          <img className='searchIcon' src={notification} alt="notification" onClick={() => navigate('/notifications')} />
      </div>
      <div className="travelle-header-sec3Items2">
          <img className="travelle-header-sec3Items2-img" src={companyLogo} alt="user" />
          <p >{userData?.companyName || 'User'}</p>
          <FaAngleDown />
      </div>
      <div className="travelle-header-sec3Items">
          <img className='searchIcon' src={searcIcon} alt="search" />
      </div>
  </div>
      
      :
       <div className='company-header-text2'>
       <p>Contact Us</p>
       <p>Already Listed?</p>
       <button onClick={handleLoginasCompany}>Login</button>
       <img src={searcIcon} alt="" onClick={handleNotificationIcon} />
     </div>

      }
     
    </div>
  )
}

export default CompanyHeader
