import React from 'react'
import "./TravellerNotifications.css"
import leftArrow from "../../../Company/images/arrowLeft.svg"
import notifyMe from "../../../Company/images/notifyMe.svg"

const TravellerNotification = () => {
  return (
    <div className='company-notification-container'>
      <div className="comNot-main">
        <div className='comNot-headline-conatiner'>
          <img src={leftArrow} alt="" />
          <h2>Notifications</h2>
        </div>
        <div className='comNot-filter-conatiner'>
          <select name="" id="">
            <option value="">Recent</option>
            <option value="">Older</option>
            <option value="">Unread</option>
            <option value="">Read</option>
          </select>
        </div>
        <div className='comNot-subheadline-conatiner'></div>
        <div className='comNot-item-conatiner'>
          <div className='comNot-item'>
            <img src={notifyMe} alt="" />
            <p>A customer has successfully purchased a ticket. View details <span>here</span></p>
            <span>10:14 am</span>
          </div>
          <div className='comNot-item'></div>
        </div>
      </div>
      
    </div>
  )
}

export default TravellerNotification
