import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadProfilePicture, updateCompanyData } from '../../../../actions/authActions'
import "./UserProfile.css"
import GigmLogo from "../../images/GigmLogo.svg"
import phoneIcon from "../../images/phone.svg"
import emailIcon from "../../images/email.svg"
import FbIcon from "../../images/facebook.svg"
import TwtIcon from "../../images/twitter.svg"
import IgIcon from "../../images/instagram.svg"
import user2 from "../../images/user2.svg"



const UserProfile = () => {
  const [loading, setLoading] = useState(false)
  const [editProfile, setEditProfile] = useState(false)
  const dispatch = useDispatch();
  const { imageUrl, error } = useSelector((state) => state.auth);
  const userData = useSelector((state)=>state.auth.userData)
  // const token = useSelector((state)=>state.auth.token)
  // const userId = useSelector((state)=>state.auth.userId)

  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const companyLogo =userData.data.url
  // // const userData = localStorage.getItem('userData')
  // const userData = JSON.parse(localStorage.getItem('userData'));

  console.log(userData.data.companyName)

  const [formData, setFormData]= useState({
    companyName: userData?.companyName || '',
    companyAcronym: userData?.companyAcronym || '',
    phoneNumber: userData?.phoneNumber || '',
    address: userData?.address || '',
    aboutUs: userData?.aboutUs || '',
    facebookHandle: userData?.facebookHandle || '',
    twitterHandle: userData?.twitterHandle || '',
    instagramHandle: userData?.instagramHandle || '',
  })

    const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleImageChange = (event) => {
    const companyPicture = event.target.files[0];
    if (companyPicture) {
        dispatch(uploadProfilePicture(companyPicture, userId, token));
    }
};

const handleSubmit = (e) => {
  e.preventDefault();
  setLoading(true);

  const updatedData = new FormData();

  // Append all form data to FormData object
  Object.keys(formData).forEach((key) => {
      updatedData.append(key, formData[key]);
  });

  dispatch(updateCompanyData(updatedData, userId, token))  // updateCompanyData to match the company-specific action
    .then((response) => {
      // Assuming your response has the structure you provided
      if (response && response.message) {
        alert(response.message); // Alert the response message
      }
      setEditProfile(false); // Set editProfile to false upon success
    })
    .catch((error) => {
      console.error("Error updating data:", error);
      alert("Failed to update user data. Please try again."); // Optional: alert for failure
    })
    .finally(() => {
      setLoading(false); // Stop loading regardless of success or failure
    });
};


  const handleEditProfile = ()=>{
    setEditProfile(true)
  }
  const handleSavebtn = ()=>{
    setEditProfile(false)
  }

  return (
    <form className='companyside-main-content' onSubmit={handleSubmit}>
    {editProfile ? 
    
    <>
    <div className='company-dash-toplayer'> </div>
    <div className='company-editProfile-topsection'>
      <h4>Logo</h4>
      <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                disabled={loading}
            />
            {loading && <p>Uploading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {imageUrl && <img src={imageUrl} alt="Profile" style={{ width: '150px', height: '150px', borderRadius: '50%' }} />}
        
      {/* <img src={user2} alt="" /> */}
      <div className='company-editprofile-topsection-inputfield'>
      <div className='company-editprofile-topsection-inputfield-item'>
        <label> Company Name</label>
        <input type="text" name='companyName' value={formData.companyName} onChange={handleInputChange} />
      </div>
      </div>
      <div className='company-editprofile-topsection-inputfield2'>
      <div className='company-editprofile-topsection-inputfield-item'>
        <label>Phone Number</label>
        <input type="text" name='phoneNumber' value={formData.phoneNumber} onChange={handleInputChange} />
      </div>
      <div className='company-editprofile-topsection-inputfield-item'>
        <label> Email Address</label>
        <input type="text" name='email' value={formData.email} onChange={handleInputChange}/>
      </div>
      </div>
    </div>
    <div className='company-editProfile-secondSection'>
      <h4>About Us</h4>
      <input type="text" placeholder='Type something...' name='aboutUs' value={formData.aboutUs} onChange={handleInputChange}/>
    </div>
    <div className='company-editProfile-lastSection'>
      <div className='company-editprofile-lasction-item'>
      <div className='companydash-userprofile-lastsection-item'>
        <label>facebook</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" name='facebookHandle' value={formData.facebookHandle} onChange={handleInputChange}/><img src={FbIcon} alt="" /></div>
      </div>
      <div className='companydash-userprofile-lastsection-item'>
        <label>Twitter</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" name='twitterHandle' value={formData.twitterHandle} onChange={handleInputChange} /><img src={TwtIcon} alt="" /></div>
      </div>
      </div>
      <div className='company-editprofile-lasction-item'>
      <div className='companydash-userprofile-lastsection-item'>
        <label>Instagram</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" name='instagramHandle' value={formData.instagramHandle} onChange={handleInputChange} /><img src={IgIcon} alt="" /></div>
      </div>
      </div>
    </div>
    <button className='saveBtn' type='submit'>{loading ? 'saving...' : 'save'}</button>


    </>:
    
    <>
    <div className='company-dash-toplayer'> 
      <button onClick={handleEditProfile}>Edit Profile</button>
      </div>
  <div className='companyside-main-container'>
    <div className='companydash-userProfile-section1'>
      <div className='companydash-userProfile-section1Main'>
        <div className='companydash-userProfile-section1Main-row1'>
          <img className='company-profile-logo' src={companyLogo} alt="logo" />
          <h3>{userData.data.companyName}</h3>
        </div>
        <h4>Contact Details</h4>
        <div className='companySide-userProfile-contactHolder'>
          <img src={phoneIcon} alt="" />
          <label >+234-900-123-4236</label>
        </div>
        <div className='companySide-userProfile-contactHolder'>
          <img src={emailIcon} alt="" />
          <label >{userData.data.email}</label>
        </div>
      </div>
    </div>
    <div className='companydash-userProfile-section1'>
      <div className='companydash-userprofile-aboutUsHolder'>
        <h3>About Us:</h3>
        <div className='companySide-aboutUS-content'>
        Porem ipsum dolor sit amet, consectetur adipiscing elit. 
        Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
         dignissim, metus nec fringilla accumsan, risus sem sollicitudin
          lacus, ut interdum tellus elit sed risus. Maecenas eget 
          condimentum velit, sit amet feugiat lectus. Class aptent 
          taciti sociosqu ad litora torquent per conubia nostra, per 
          inceptos himenaeos. Praesent auctor purus luctus enim egestas, 
          ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse
           ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem.
            Morbi convallis convallis diam sit amet lacinia. Aliquam in 
            elementum tellus.
        </div>
      </div>
    </div>
    <div className='companydash-userProfile-section3'>
      <div className='companydash-userprofile-lastsection-header'>
        Socials
      </div>
      <div className='companydash-userprofile-lastsection-item-main'>
      <div className='companydash-userprofile-lastsection-item'>
        <label>facebook</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" /><img src={FbIcon} alt="" /></div>
      </div>
      <div className='companydash-userprofile-lastsection-item'>
        <label>Twitter</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" /><img src={TwtIcon} alt="" /></div>
      </div>
      </div>
      <div className='companydash-userprofile-lastsection-item-main'>
      <div className='companydash-userprofile-lastsection-item'>
        <label>Instagram</label>
        <div className='comapnydash-userprofile-socialHandle-input'><input type="text" /><img src={IgIcon} alt="" /></div>
      </div>
      
      </div>
    </div>
  </div>
    </>


    
    }
  
  </form>
  )
}

export default UserProfile