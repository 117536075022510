import React, { useState } from "react";
import "./transportCompnyDetails.css"
import { IoMdArrowBack } from "react-icons/io";
import companyImage from "../../images/company1.png"
import { MdStarRate } from "react-icons/md";
import { FaTag } from "react-icons/fa6";
import { FaNairaSign } from "react-icons/fa6";
import igLogo from "../../images/igIcon.png"
import fbLogo from "../../images/fbIcon.png"
import twitterLogo from "../../images/twitterIcon.png"
import motorIcon from "../../images/bus.png"
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";


const TransportCompanyDetailes = () => {
    const [transportAbout, setTransportAbout] = useState(true)
    const [transportTrips, setTransportTrips] = useState(false)
    const [transportTerminals, settransportTerminals] = useState(false)
    const [viewTerminals, setViewTerminals] = useState(false)


    const handleViewStatus = ()=>{
        setViewTerminals(!viewTerminals)
    }
    return (
        <div className="TransportDetails-mainContainer">
            <div className="TransportDetails-main">
                <div className="traveleerSide-ttransporcompanies-topsec123">
                    <h2>TRANSPORT COMPANIES</h2>
                </div>
                <div className="transportDetails-topsec">
                    <IoMdArrowBack /> <h5>Back</h5>
                </div>
                <div className="transportDetails-sec2">
                    <img src={companyImage} alt="" />
                    <div className="transportDetails-sec2a">
                        <h3>GUO Transport Company</h3>
                        <div className="transportDetails-Rating">
                            4.5 <MdStarRate />
                        </div>
                        <div className="transportDetails-priceTag">
                            <FaTag  color="red" size={15}/> <>Starting from <div><FaNairaSign size={12} /> 12,500</div></>
                        </div>
                        <h6>Destinations : Lagos, Abuja, Port Harcourt, Ibadan,
                            Enugu </h6>


                    </div>
                </div>
                <div className="transportDetails-sec3">
                    <div className="transportDetails-sec3-top">
                        <p className={transportAbout && "transportDetails-sec3-top-active"} onClick={() => {
                            setTransportAbout(!transportAbout)
                            setTransportTrips(false)
                            settransportTerminals(false)
                        }}>About</p>
                        
                        <p className={transportTrips && "transportDetails-sec3-top-active"} onClick={() =>
                            {   setTransportTrips(!transportTrips)
                                setTransportAbout(false)
                                settransportTerminals(false)
                            }}>Available Trips</p>
                        
                        <p className={transportTerminals && "transportDetails-sec3-top-active"} onClick={() =>{
                             settransportTerminals(!transportTerminals)
                            setTransportTrips(false)
                            setTransportAbout(false)
                             }}>Terminals</p>
                    </div>
                    {
                        transportAbout ? 

                        <div className="transportDetails-sec4">
                    <p>Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.
                        Curabitur tempor quis eros tempus lacinia. Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.
                    </p>
                   <>
                   <h5>Photos</h5>
                   <div className="tranportDetails-imgHolder">
                        <img src="" alt="" />
                        <img src="" alt="" />
                        <img src="" alt="" />
                    </div>
                   </>
                   <>
                   <h5>Socials</h5>
                   <div className="transportDetails-Socials">
                             <div className="transportDetails-Socials-item1">
                                <img src={igLogo} alt="" /> <h5>Instagram</h5>
                             </div>
                             <div className="transportDetails-Socials-item2">
                                <img src={fbLogo} alt="" /> <h4>Facebook</h4>
                             </div>
                             <div className="transportDetails-Socials-item3">
                                <img src={twitterLogo} alt="" /> <h5>Twitter</h5>
                             </div>
                   </div>
                   </>
                </div>:

                        transportTrips ?

                        <div className="transportDetails-trips">
                                <div className="transportDetails-trips-item1">
                                    <div className="transportDetails-trips-item1-top">
                                            <p>#GTC1260TYH</p>
                                    </div>
                                    <div className="transportDetails-trips-item1-mid">
                                        <div className="transportDetails-trips-item1-mida">
                                            <p>ABJ</p>
                                            <p>6:30AM</p>
                                        </div>
                                        
                                        <div className="transportDetails-trips-item1-midb">
                                            <div className="locationDot2"></div>
                                            <img src={motorIcon} alt="" />
                                            <div className="locationDot2"></div>
                                        </div>
                                       
                                        <div className="transportDetails-trips-item1-midc">
                                        <p>9:30pM</p>
                                        <p>LAG</p>
                                        </div>
                                    </div>
                                    <div className="transportDetails-trips-item1-btm">
                                        <h5>10 seats left</h5>
                                        <div className="transportDetails-trips-item1-btmA"><FaNairaSign size={12} /> <p>11,500 / person</p></div>
                                    </div>
                                </div>

                                
                                
                        </div> : 

                        transportTerminals ?
                         <div className="transportDetails-Terminals">
                                <div className="transportDetails-Terminals-item1">
                                    <div className="transportDetails-Terminals-item1a">
                                    <h4 onClick={handleViewStatus}>Lagos</h4>
                                    {viewTerminals ? <>
                                        <FaAngleDown />
                                    </> : <>
                                        <FaAngleRight />
                                    </>}
                                    
                                    </div>
                                    {
                                        viewTerminals&&
                                        <div className="transportDetails-Terminals-item1b">
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                        <button>Ikeja</button>
                                    </div>
                                    }
                                </div>
                               
                        </div> :null
                    }
                    
                </div>
                




            </div>
        </div>
    )
}

export default TransportCompanyDetailes