import React from 'react'
import "./driverLogin.css"
import { useNavigate } from 'react-router-dom'

const DriverLogin = () => {
    const navigate = useNavigate()

    const handleDriverFOrgetPasswordBTN = ()=>{
        navigate('/driverresetpassword')
    }
    const handleDRiverLOginSuccess = ()=>{
        navigate('/driverdashboard')
    }
  return (
    <div className='driverSignup-holder'>
            <div className="driverSignup-mainContainer2">
        <div className="driverSignup-mainContainer-item1a">
                <h2>Create Password</h2>
                <h5>Use a minimum of 10 characters, including uppercase letters, lowercase letters and numbers.</h5>
            </div>
            <div className="driverSignup-mainContainer-item2">
                <label htmlFor="">Driver's ID</label>
                <input type="password" />
            </div>
            <div className="driverSignup-mainContainer-item2">
                <label htmlFor="">Password</label>
                <input type="password" />
            </div>
            <div className="driverSignup-mainContainer-item6">
                <div className="driverSignup-mainContainer-item6a">
                    <input type="checkbox" /><p>remember me</p>
                </div>
                <p onClick={handleDriverFOrgetPasswordBTN}>Forgot password?</p>
            </div>
            <button onClick={handleDRiverLOginSuccess}>Login</button>
        </div>

    </div>
  )
}

export default DriverLogin