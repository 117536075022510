import React, { useEffect, useState } from 'react'
import "./CompanyLogin.css"
import { useNavigate } from 'react-router-dom'
import { login } from '../../../../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import Swal from "sweetalert2"


const CompanyLogin = () => {
const [formData, setFormData] = useState({
  email:"",
  password:"",
})
const [loading, setLoading] = useState(false)
const navigate = useNavigate();
const dispatch = useDispatch();
const {token, userId, error} = useSelector((state)=>state.auth)

const handleChange = (e)=>{
  setFormData({
    ...formData,
    [e.target.name] : e.target.value,
  });
};

const validateForm = () =>{
  if(!formData.email){
    Swal.fire('Error', 'email is required', 'error');
    return false;
  }
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(formData.email)) {
    Swal.fire('Error', 'Enter a valid email', 'error');
    return false;
  }
  if(!formData.password){
    Swal.fire('Error', "Password is required", 'error')
    return false
  }
  return true
}

const handleCOmpanyLogin= (e)=>{
  e.preventDefault();
  if(validateForm()){
    setLoading(true)
    dispatch(login(formData, 'company'))
      .finally(()=>setLoading(false));
  }
};
useEffect(()=>{
  if(token && userId){
    Swal.fire('Success', 'login-Successful', 'success').then(()=>{
    navigate("/companydashboard")
    });
  } else if (error){
    Swal.fire('Error', error, 'error');
  }
}, [token, userId, dispatch, navigate])

  return (
   <form onSubmit={handleCOmpanyLogin}>
     <div className='comapnyLogin-container'>
    <div className='company-login-content'>
      <h2>Sign In</h2>
      <h5>Access your account with your email address and passwords.</h5>
      <div className='login-email-holder' >
        <label>Email</label>
        <input type="email" placeholder='Email' name='email' value={formData.email} onChange={handleChange} />
      </div>
      <div className='login-email-holder'>
        <label> Password</label>
        <input type="password" placeholder='password' name='password' value={formData.password} onChange={handleChange}/>
      </div>
      
      <button type='submit' disabled={loading}> {loading ? 'Logging in...' : 'Login'}</button>
     
    </div>
  </div>
   </form>
  )
}

export default CompanyLogin
