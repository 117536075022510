import React, { useState } from 'react'
import "./CompanyHeader.css"
import companyLogo from "../images/logoW.svg"
import { useNavigate } from 'react-router-dom'
import driverImg from "../../TravellerSide/images/profile.jpg"
import { IoIosArrowForward } from "react-icons/io";
import { BiMenuAltRight } from "react-icons/bi";

const CompanyHeader4 = () => {
  const [userIsTrue, setUserIsTrue] = useState(false)

  const navigate = useNavigate()


  const handleLoginasCompany=()=>{
    navigate("./driverlogin")
  }
 
  return (
    <div className='companyHeader-container'>
      <img src={companyLogo} alt="" />
      <div className='company-header-text2'>
        <p>Contact Us</p>
        {
          userIsTrue?
          <div className='company-header-text2-item12'>
          <img src={driverImg} alt="" />
          Collins
          <IoIosArrowForward />
        </div>:
          <button onClick={handleLoginasCompany}>Login</button>
        }
        
        
        
      </div>
      <div className="company-header-text2-mobile">
          <BiMenuAltRight size={20}/>
      </div>
    </div>
  )
}

export default CompanyHeader4
