import React from 'react'
import "./travellerfqs.css"
import { useState } from 'react'
import travFaqImg from "../images/travFAQImg.svg"

const TravellerFAQS = () => {

  const [faQues, setFaQues] = useState(false)


 const handleFAQuesCHnage = ()=>{
  setFaQues(!faQues)
 }
  return (
    <div className='travellerFAQs-main'>
        <div className="traveller-aboutUs-topSection">
          <h1>FAQs</h1>
        </div>
        <div className="travellerFAQs-main-container">
        <div className='FAQues-mainContainer2'>
        <div className='FAQues-leftSection'>
          <div className="FAQues-lefSec-topSec">
            <h5>Frequently Asked Questions</h5>
            <h3>Do you have any question?</h3>
            <p>Here you can find information on our services, products, technical and legal issues</p>
          </div>
          <div className="FAQues-lefSec-bottomSec">
            <div className='FAQues-items'>
            <div className="FAQues-holder">
              <p>How does VadTrans work with Transport Companies?</p> <p onClick={handleFAQuesCHnage}
              >{faQues? '-' : '+'}</p>
            </div>
            {faQues&& 
                   <div className="FAQues-answer">
                   <p>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus.</p>
                 </div>
            }
         
            </div>
            <div className='FAQues-items'>
            <div className="FAQues-holder">
              <p>How does VadTrans work with Transport Companies?</p> <p onClick={handleFAQuesCHnage}
              >{faQues? '-' : '+'}</p>
            </div>
            {faQues&& 
                   <div className="FAQues-answer">
                   <p>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus.</p>
                 </div>
            }
         
            </div>
            <div className='FAQues-items'>
            <div className="FAQues-holder">
              <p>How does VadTrans work with Transport Companies?</p> <p onClick={handleFAQuesCHnage}
              >{faQues? '-' : '+'}</p>
            </div>
            {faQues&& 
                   <div className="FAQues-answer">
                   <p>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus.</p>
                 </div>
            }
         
            </div>
            <div className='FAQues-items'>
            <div className="FAQues-holder">
              <p>How does VadTrans work with Transport Companies?</p> <p onClick={handleFAQuesCHnage}
              >{faQues? '-' : '+'}</p>
            </div>
            {faQues&& 
                   <div className="FAQues-answer">
                   <p>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus.</p>
                 </div>
            }
         
            </div>
            <div className='FAQues-items'>
            <div className="FAQues-holder">
              <p>How does VadTrans work with Transport Companies?</p> <p onClick={handleFAQuesCHnage}
              >{faQues? '-' : '+'}</p>
            </div>
            {faQues&& 
                   <div className="FAQues-answer">
                   <p>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus.</p>
                 </div>
            }
         
            </div>
          </div>
        </div>
        <div className='FAQues-rightSection2'>
          <img src={travFaqImg} alt="" />
        </div>
      </div>
        </div>
    </div>
  )
}

export default TravellerFAQS
