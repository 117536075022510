import React, { useState } from 'react'
import "./drivertripDetails.css"
import MapImg from "../../../TravellerSide/images/map.png"
import motoImg from "../../../TravellerSide/images/motoLogo.png"
import motologo from "../../../TravellerSide/images/busIcon.png"
import { FaArrowRight } from "react-icons/fa";

const DriverTripsDetail = () => {
    const [driverLocation, setDriverLocation] = useState(false)
    const [driverLocationBtn, setDriverLocationBtn] = useState(true)
    const handleDriverLocationIsTrue = ()=>{
        setDriverLocation(true)
        setDriverLocationBtn(false)
    }
  return (
    <div className='driver-tripsDetails-mainCOntainer'>
        <div className="driver-tripsDetails-holder">
            <h2>Trip Details</h2>
            <div className="driver-tripsDetails-item1">
                <div className="driver-tripsDetails-item1a">
                    <div className="driver-tripsDetails-item1a-item1">
                            <img src={motoImg} alt="" />
                            <div className="driver-tripsDetails-item1a-item1a">
                                <h4>GUO Transport Company</h4>
                                <h6>GIGM286GD</h6>
                            </div>
                    </div>
                    <div className="driver-tripsDetails-item1a-item2">
                        <img src={motologo} alt="" />
                        <h4>6:00AM</h4>
                        <div className="driver-tripsDetails-item1a-item2a">
                            <p>ABJ</p>
                            <FaArrowRight color='red'/>
                            <p>LAG</p>
                        </div>
                        <h4>9:30PM</h4>
                        <h6>12 hrs 20 mins</h6>
                    </div>
                    <div className="driver-tripsDetails-item1a-item3">
                        <h6>20 April 2024</h6>
                    </div>
                    <div className="driver-tripsDetails-item1a-item4">
                        <h5>Vehicle: Toyota Coaster Bus</h5>
                        <span>Serial No: GIGM-321</span>
                        <span>License Plate: IKJ- 123TY</span>
                    </div>
                </div>

                <div className="driver-tripsDetails-item1b">
                    <div className="driver-tripsDetails-item1b-item1">
                        <h4>No. of Passengers</h4>
                        <h5>30 passengers</h5>
                    </div>
                    <div className="driver-tripsDetails-item1b-item1">
                        <h4>Destination Terminal</h4>
                        <h5>Berger Terminal</h5>
                    </div>
                </div>
            </div>
            {
                driverLocation && 
                <div className="driver-tripsDetails-item3">
                <h4>Your current location</h4>
                <div className="driver-tripsDetails-item3a">
                    <h5>Chicken Republic, 
                    4, John Holt Way, Kpata, Marine/ Kpata, Lokoja 260101, Kogi, Nigeria</h5>
                    <h4>11:59 AM</h4>
                </div>
            </div>
            }
            
            <div className="driver-tripsDetails-item2">
                <div className="driver-tripsDetails-item2a"> <img src={MapImg} alt="" /></div>
               {
                driverLocationBtn? 
                <button onClick={handleDriverLocationIsTrue}>Start Trip</button> : null

               }
            </div>
            {
                driverLocation && 
                <div className="driver-tripsDetails-item4">
                <button className="driver-btn1">Update Location</button>
                <button className="driver-btn2">Stop Trip</button>
                <button className="driver-btn3">End Trip</button>
                <button className="driver-btn4"> Continue Trip</button>
            </div>
            }
            
        </div>
    </div>
  )
}

export default DriverTripsDetail