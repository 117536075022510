import React, { useState } from 'react'
import "./CompanyTickets.css"

import searchIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/search.svg"
import filterIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/filter.svg"
import navIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/nav.svg"
import doubleArrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-left.svg"
import arrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-left.svg"
import arrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-right.svg"
import doubleArrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-right.svg"
import arrorRightRed from "../../images/arrowRightRed.svg"
import printLogo from "../../images/print.png"
import gigmLogo from "../../images/GigmLogo2.svg"
import ticketYellow from "../../images/tickYellow.svg"
import barCode from "../../images/barcode.svg"


const CompanyTickets = () => {
const [companyTicketsDetails, setCompanyTicketsDetails] = useState(true)

const handleCompanyTicketdetails = ()=>{
  setCompanyTicketsDetails(false)
}

  return (
    <div className='CompanyTickets-container'>
      <div className='company-dash-toplayer'></div>
      {companyTicketsDetails? 
      
      <>
      
      <h3>All Tickets</h3>
      <div className='company-tickets-maincontainer'>
      <div className='clientManagement-history'>
<div className='clientManagement-history-searchbar'>
<input type="text" />
<img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
<div className='filterContainer'> 
<img className='filterContainer-img' src={filterIcon} alt="" />
  
</div>
</div>
<div className='clientManagement-history-labels'>
<p>Ticket ID</p>
<p>Passenger</p>
<p>Amount</p>
<p>Trip</p>
<p>Trip Date</p>

</div>
<div className='clientManagement-list-holder'>
<div className='clientManagement-list-item'>
<p>GIGM286GD</p>
<p>Jane Doe</p>
<p>#12,000</p>
<div className='company-tickets-trip'>
  <p>Lag</p> <img src={arrorRightRed} alt="" /><p>Abj</p>
</div>
<p>04 Oct 2023</p>
  <div className='clientManagement-list-item-img2-container'>
  <img  className='clientManagement-list-item-img2' onClick={handleCompanyTicketdetails} src={navIcon} alt="nav Icon" />
  </div>
</div>

</div>
<div className='clientManagement-history-navigation'>
<div className='clientManagement-history-item'>
  <img src={doubleArrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2-active'>
  <p>1</p>
</div>
<div className='clientManagement-history-item2'>
  <p>2</p>
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowRight} alt="" />
</div>
<div className='clientManagement-history-item3'>
  <img src={doubleArrowRight} alt="" />
</div>

</div>

</div>
      </div>
      
      
      </>:
      
      <>
      <div className='companyTicket-details-container'>
        <h3>Ticket Information</h3>
        <div className='print-btn-holder'><img src={printLogo} alt="" /><h5>Print</h5></div>
        <div className='company-ticket-details-mainContainer'>
        <div className="company-ticket-details-section1">
          <img src={gigmLogo} alt="" />
          <div>
            <h4>GUO Transport Company</h4>
            <p>GIGM286GD</p>
            </div>
        </div>
        <div className="company-ticket-details-section2">
          <div className='company-ticket-details-section2-items'>
            <div className='company-ticket-details-section2-item'><img src={ticketYellow} alt="" /> <h2>6:00 AM</h2> <p>ABJ</p></div>
              <div className='company-ticket-details-section2-item-img'> 
               <img src={arrorRightRed} alt="" />
              </div>
            <div className='company-ticket-details-section2-item'><p>LAG</p> <h2>9:30 PM</h2> <span>12 hrs 20 mins</span></div>
          </div>
          <p>20 April 2024</p>
        </div>
        <div className="company-ticket-details-section3">
          <div className='company-ticket-details-section3-main'>
            <div className="company-ticket-details-section3-main-top"><h5>Fare Summary</h5></div>
            <div className="company-ticket-details-section3-main-body">
              <div className='company-ticket-details-section3-main-body-item'>
                <label>Fare summary</label>
                <div className='company-ticket-details-section3-main-body-itemList'><p>Adult X 1</p> <span>#15,800</span>
              </div>
               </div>
            <div className='company-ticket-details-section3-main-body-item'>
                <div className='company-ticket-details-section3-main-body-itemList'><label>tax</label> <span>#200</span>
                </div>
              </div>
              <div className='company-ticket-details-section3-main-body-item'>
                <label >Total</label>
                <div className='company-ticket-details-section3-main-body-itemList'><label>1 traveler</label> <span className='mainSpan'>#16,000</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="company-ticket-details-section4">
          <img src={barCode} alt="" />
        </div>


      </div>
      </div>
       
      </>}
      
    </div>
  )
}

export default CompanyTickets
